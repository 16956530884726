.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: right;
  height: 30px;
}
.navHeaderRight {
  height: 45px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-right: 50px;
}
.navbarHeaderRight {
  margin-right: 20px;
}
.signUp {
  margin-top: 10px;
  text-align: center;
}
.signupPage-wrapper {
  margin: 0 auto;
  max-width: 980px;
  margin-top: 20px;
}
.signupPage-wrapper-full .logo-wrapper{
  margin-top: 20px;
}
.signupPage{
  padding: 20px 20px 20px 20px !important;
  background: rgba(255,255,255, 1);
  z-index: 3;
}
/* .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #03d665;
} */
/* @media screen and (min-width: 992px) {
  .signupPage-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
} */
/* .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #03d665 !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: #03d665 !important;
} */
.loginBack {
  font-size: 14px;
  margin-bottom: 14px;
  text-align: right;
}
.loginBack a{
  color: #169933 !important;
  font-weight: bold;
}
.loginBack a:hover{
  text-decoration: underline;
}
.__profile{
  background: linear-gradient(to right, #ee5a6f, #f29263);
}
.__orderForm {
  padding: 0px 100px 0px 100px;
}
.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
  margin-top: 0px;
}
.billGen {
  padding: 0px 10%;
}
.ant-table-content {
  overflow-y: auto !important;
}

.ant-input-number-input-wrap {
  height: 40px;
}
/* .ant-col.ant-form-item-control .ant-input-number {
  border: 1px solid #03D665 !important;
} */


/* ////// */

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
span.error {
  color: red;
}

/* GENERAL */
.boody1 {
	/* width: 100%;
	height: 100vh; */
	
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background-color: #A8DADC;
}

.boody2 {
	width: calc(68px * 3);
	height: calc(66px * 3);
}
/* ELEMENTS POSITIONS */
#building_bottom,
#window_01,
#window_02,
#door {
	transform: translateY(20px);
	z-index: 1;
}

#building_top,
#balcony_01,
#balcony_02,
#balcony_03,
#balcony_04,
#balcony_05,
#balcony_06 {
	transform: translateY(56px);
	z-index: -1;
}

#small_tree,
#big_tree {
	transform: translateY(28px);
}

/* ELEMENTS ANIMATIONS */
#building_bottom {
	animation: buildingBottom 1s ease-out forwards;
}

#window_01,
#window_02,
#door {
	animation: buildingBottom 1s 0.1s ease-in forwards;
}

#building_top {
	animation: buildingTop 1s 1.2s ease-out forwards;
}

#balcony_01,
#balcony_02,
#balcony_03 {
	animation: buildingTop 1s 1.4s ease-out forwards;
}

#balcony_04,
#balcony_05,
#balcony_06 {
	animation: buildingTop 1s 1.6s ease-out forwards;
}

#small_tree,
#big_tree {
	animation: trees 1s 2s ease-out forwards;
}

#cloud_left {
	transform: translateX(-11px);
	animation: cloudLeft 20s 2s linear infinite;
}

#cloud_right {
	transform: translateX(72px);
	animation: cloudRight 15s 2s linear infinite;
}

/* ANIMATIONS */
@keyframes buildingBottom {
	0% 		{ transform: translateY(20px) }
	100% 	{ transform: translateY(0) }
}

@keyframes buildingTop {
	0% 		{ transform: translateY(56px); }
	100% 	{ transform: translateY(0); }
}

@keyframes trees {
	0% 		{ transform: translateY(28px); }
	100% 	{ transform: translateY(0); }
}

@keyframes cloudLeft {
		0% 		{ transform: translateX(-11px); }
	100% 		{ transform: translateX(100px); }
}

@keyframes cloudRight {
		0% 		{ transform: translateX(72px); }
	100% 		{ transform: translateX(-100px); }
}

.ant-card.ant-card-bordered.dashbaordCard {
  border-radius: 20px;
  -webkit-box-shadow: 6px 4px 62px 0px rgb(1 201 134 / 26%);
  -moz-box-shadow: 6px 4px 62px 0px rgba(1,201,134,0.26);
  box-shadow: 6px 4px 62px 0px rgb(1 201 134 / 26%);
}


#vdel {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#vdel td, #vdel th {
  border: 1px solid #ddd;
  padding: 8px;
}

#vdel tr:nth-child(even){background-color: #f2f2f2;}

#vdel tr:hover {background-color: #ddd;}

#vdel th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.tableDropDown {
  /* width: 100%; */
  height: 40px;
  border: 1px solid #03D665;
  width: 10rem;
}
.tableDropDown:focus {
  outline:none;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
::placeholder {
  text-transform: capitalize;
}

.ant-select-selection-placeholder {
  text-transform: capitalize;
}

.select-box{
  text-align: center;
}